/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"
import { navigate } from "gatsby"

import { createClient } from "graphql-ws"
import { GraphQLWsLink } from "@apollo/client/link/subscriptions"
import { getMainDefinition } from "@apollo/client/utilities"
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  split,
} from "@apollo/client"
import { AppContextProvider } from "./src/context/appContext"
// gatsby-browser.js
import "./global.css"
import "@fontsource/roboto/100.css" // Weight 100 regular
import "@fontsource/roboto/100-italic.css" // Weight 100 italic
import "@fontsource/roboto/300.css" // Weight 300 regular
import "@fontsource/roboto/300-italic.css" // Weight 300 italic
import "@fontsource/roboto/400.css" // Weight 400 regular
import "@fontsource/roboto/400-italic.css" // Weight 400 italic
import "@fontsource/roboto/500.css" // Weight 500 regular
import "@fontsource/roboto/500-italic.css" // Weight 500 italic
import "@fontsource/roboto/700.css" // Weight 700 regular
import "@fontsource/roboto/700-italic.css" // Weight 700 italic
import "@fontsource/zcool-qingke-huangyou/400.css" // Default weight for ZCOOL QingKe HuangYou

const httpLink = new HttpLink({
  uri: process.env.GATSBY_EGPT_API_URL ?? "",
})

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.GATSBY_EGPT_WEBSOCKET_URL ?? "",
  })
)

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    )
  },
  wsLink,
  httpLink
)

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
})

export const onPreRouteUpdate = ({ location }) => {
  if (
    location.pathname === "/Techoversity/" ||
    location.pathname === "/techoversity/blogs/" ||
    location.pathname === "/iot/" ||
    location.pathname === "/connected-asset-management-platform/" ||
    location.pathname === "/techoversity/videos/"
  ) {
    navigate("/techoversity/")
  } else if (location.pathname === "/googlenext/") {
    // Redirect to another page
    navigate("/")
  } else if (
    location.pathname === "/aification-hackathon/" ||
    location.pathname === "/aification-hackathon" ||
    location.pathname === "/our-expertise/enterprise-cloud" ||
    location.pathname === "/our-expertise/enterprise-cloud/" ||
    location.pathname === "/co-create" ||
    location.pathname === "/co-create/" ||
    location.pathname === "/using-dsg" ||
    location.pathname === "/using-dsg/" ||
    location.pathname ===
      "/wp-content/uploads/2020/12/Techolution-eBook-Accelerate-Your-Banks-Cloud-Journey.pdf" ||
    location.pathname ===
      "/wp-content/uploads/2021/08/Techolution-Managed-Cloud-Services.pdf" ||
    location.pathname ===
      "/how-did-we-seamlessly-modernize-outdated-legacy-systems-with-our-ai-solution-for-a-leading-building-materials-supply-company" ||
    location.pathname ===
      "/how-did-we-seamlessly-modernize-outdated-legacy-systems-with-our-ai-solution-for-a-leading-building-materials-supply-company/"
  ) {
    navigate("/book-demo/")
  } else if (location.pathname.startsWith("/our-team")) {
    navigate("/the-dream-team/")
  } else if (
    location.pathname === "/our-awards/" ||
    location.pathname === "/our-awards"
  ) {
    navigate("/?section=awards-recognition-section")
  } else if (
    location.pathname === "/our-purpose/" ||
    location.pathname === "/our-purpose" ||
    location.pathname === "/our-expertise" ||
    location.pathname === "/our-expertise/" ||
    location.pathname === "/Ai4" ||
    location.pathname === "/Ai4/" ||
    location.pathname === "/success-stories/" ||
    location.pathname === "/success-stories"
  ) {
    navigate("/")
  } else if (
    location.pathname ===
      "/solutions/inventory-and-quality-control-automation/" ||
    location.pathname === "/solutions/inventory-and-quality-control-automation"
  ) {
    navigate("/solutions/labor-automation-with-ai-robotics/")
  } else if (
    location.pathname === "/wow-digital-experience/" ||
    location.pathname === "/wow-digital-experience"
  ) {
    navigate("/digital-transformation-doesn-t-have-to-be-painful/")
  } else if (
    location.pathname === "/cloud-automation-services/" ||
    location.pathname === "/our-expertise/enterprise-cloud/cloud-automation/" ||
    location.pathname ===
      "/our-expertise/enterprise-cloud/real-time-data-streaming/" ||
    location.pathname ===
      "/our-expertise/enterprise-cloud/enterprise-cloud-foundation/" ||
    location.pathname === "/our-expertise/enterprise-cloud/edge-compute-iot/" ||
    location.pathname ===
      "/our-expertise/enterprise-cloud/enterprise-data-management/" ||
    location.pathname === "/cloud-automation-services"
  ) {
    navigate("/solutions/cloud-foundation-and-data-platform-for-ai/")
  }

  if (location.pathname.startsWith("/post/")) {
    const newPath = location.pathname.replace("/post", "")
    navigate(newPath)
  } else if (
    location.pathname === "/robotics-edge-device-fabrication/" ||
    location.pathname === "/robotics-edge-device-fabrication"
  ) {
    navigate("/accelerators/robotics-and-edge-device-fabrication/")
  } else if (
    location.pathname === "/healthcare-industry/" ||
    location.pathname === "/healthcare-industry"
  ) {
    navigate("/ai-for-healthcare/")
  } else if (
    location.pathname === "/application-software-deployment-checklist//1000/" ||
    location.pathname === "/application-software-deployment-checklist//1000" ||
    location.pathname === "/application-software-deployment-checklist/1000/" ||
    location.pathname === "/application-software-deployment-checklist/1000"
  ) {
    navigate("/application-software-deployment-checklist/")
  } else if (
    location.pathname === "/managed-cloud-services" ||
    location.pathname === "/managed-cloud-services/"
  ) {
    navigate("/the-business-value-of-managed-cloud-services-explained/")
  } else if (
    location.pathname === "/government-services" ||
    location.pathname === "/government-services/"
  ) {
    navigate("/ai-for-government/")
  } else if (
    location.pathname === "/accelerators/enterprise-llm-studio/" ||
    location.pathname === "/accelerators/enterprise-llm-studio"
  ) {
    navigate("/products/llm-studio-for-enterprises/")
  } else if (
    location.pathname ===
      "/the-roadmap-to-ethical-ai-how-ggc-framework-keeps-your-ai-in-check-new/" ||
    location.pathname ===
      "/the-roadmap-to-ethical-ai-how-ggc-framework-keeps-your-ai-in-check-new"
  ) {
    navigate(
      "/the-roadmap-to-ethical-ai-how-ggc-framework-keeps-your-ai-in-check/"
    )
  } else if (
    location.pathname === "/accelerators/appmod-ai/" ||
    location.pathname === "/accelerators/appmod-ai" ||
    location.pathname === "/solutions/legacy-code-modernization-ai/" ||
    location.pathname === "/solutions/legacy-code-modernization-ai" ||
    location.pathname === "/solutions/developer-productivity-ai/" ||
    location.pathname === "/solutions/developer-productivity-ai" ||
    location.pathname ===
      "/our-expertise/enterprise-cloud/application-modernization/" ||
    location.pathname ===
      "/our-expertise/enterprise-cloud/application-modernization"
  ) {
    navigate("/products/appmod-ai-for-enterprises/")
  } else if (
    location.pathname ===
      "/a-groundbreaking-approach-to-simplifying-real-world-complex-data-with-graphformer/" ||
    location.pathname ===
      "/a-groundbreaking-approach-to-simplifying-real-world-complex-data-with-graphformer"
  ) {
    navigate("/graphformer-a-hybrid-approach-to-complex-relational-data/")
  } else if (
    location.pathname === "/our-expertise/enterprise-cloud/hybrid-cloud/" ||
    location.pathname === "/our-expertise/enterprise-cloud/cloud-security/" ||
    location.pathname ===
      "/our-expertise/enterprise-cloud/enterprise-cloud-foundation/" ||
    location.pathname === "/cloud/" ||
    location.pathname === "/our-expertise/enterprise-cloud/360-governance/" ||
    location.pathname === "/our-expertise/enterprise-cloud/cloud-migration/"
  ) {
    navigate("/google-cloud/")
  } else if (
    location.pathname === "/our-expertise/product-innovation/" ||
    location.pathname === "/our-expertise/real-world-ai/"
  ) {
    navigate("/?section=our-unique-value-container")
  } else if (
    location.pathname === "/ai-fication/" ||
    location.pathname === "/contact-us/"
  ) {
    navigate("/book-demo/")
  } else if (
    location.pathname === "/application-migration-planning-and-execution/"
  ) {
    navigate(
      "/application-migration-planning-and-execution-for-real-business-results/"
    )
  } else if (
    location.pathname ===
    "/achieving-a-i-business-outcomes-with-techolutions-autoai/"
  ) {
    navigate("/achieving-a-i-business-outcomes-with-techolution-s-autoai/")
  } else if (location.pathname === "/google-anthos/") {
    navigate("/why-anthos-is-a-game-changer-and-how-to-get-started/")
  }
}

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <AppContextProvider>{element}</AppContextProvider>
  </ApolloProvider>
)
